import React from "react";

const ProductLongLoader = () => {
  return (
    <div role="status" className="space-y-2.5 !mt-[30px] mb-[40px] max-w-full">
         <div className="h-[50px] bg-gray-200 rounded-[6px]  w-[50%] mb-[30px]"></div>
      <div className="flex flex-col items-start w-full gap-3">
        <div className="h-[20px]  bg-gray-300 rounded-[6px]  w-[90%]"></div>
        <div className="h-[20px] bg-gray-200 rounded-[6px]  w-full"></div>
        <div className="h-[20px]  bg-gray-300 rounded-[6px]  w-full"></div>
      </div>
    
    </div>
  );
};

export default ProductLongLoader;
